import { SVGProps } from 'react'

const Static = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M0 16.667h13.333V0H0v16.667zM0 30h13.333V20H0v10zm16.667 0H30V13.333H16.667V30zm0-30v10H30V0H16.667z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)

export default Static
