import { SVGProps } from 'react'

const Wired = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M30 15.996v-.742a.746.746 0 0 0-.75-.741H16.125v-1.857H19.5c.828 0 1.5-.664 1.5-1.484V5.234c0-.82-.672-1.484-1.5-1.484h-9c-.828 0-1.5.665-1.5 1.484v5.938c0 .82.672 1.484 1.5 1.484h3.375v1.856H.75a.754.754 0 0 0-.53.217.738.738 0 0 0-.22.525v.742c0 .197.079.386.22.526.14.139.331.217.53.217h4.875v1.855H3c-.828 0-1.5.664-1.5 1.484v5.938c0 .82.672 1.484 1.5 1.484h7.5c.828 0 1.5-.665 1.5-1.484v-5.938c0-.82-.672-1.484-1.5-1.484H7.875v-1.856h14.25v1.856H19.5c-.828 0-1.5.664-1.5 1.484v5.938c0 .82.672 1.484 1.5 1.484H27c.828 0 1.5-.665 1.5-1.484v-5.938c0-.82-.672-1.484-1.5-1.484h-2.625v-1.856h4.875c.414 0 .75-.332.75-.742zM12 9.687V6.72h6v2.968h-6zM9 24.532H4.5v-2.968H9v2.968zm16.5 0H21v-2.968h4.5v2.968z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)

export default Wired
