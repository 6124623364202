import { SVGProps } from 'react'

const Approval = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M11.362 24.245a.578.578 0 0 0-.533-.334.569.569 0 0 0-.522.318l-.4.787a.835.835 0 0 1-.803.452.83.83 0 0 1-.731-.558l-.877-2.588-.549 1.633a2.246 2.246 0 0 1-2.133 1.514h-.647a.827.827 0 0 1-.807-.82c0-.443.357-.806.807-.82h.645a.583.583 0 0 0 .554-.394l.947-2.8a1.25 1.25 0 0 1 1.186-.84 1.25 1.25 0 0 1 1.187.84l.722 2.135a2.366 2.366 0 0 1 1.886-.404c.658.14 1.224.551 1.551 1.13a.591.591 0 0 0 .487.322v-4.213L20 13.088v-2.385h-7.083a1.244 1.244 0 0 1-1.25-1.23V2.5H1.25a1.255 1.255 0 0 0-.886.359A1.215 1.215 0 0 0 0 3.73v23.79c-.001.326.13.64.364.871.235.231.554.36.886.359h17.499c.332.001.65-.128.885-.359.235-.23.366-.545.365-.871v-2.051l-6.667-.006a2.24 2.24 0 0 1-1.97-1.218zM19.999 8.75c0-.325-.131-.636-.365-.866l-5.098-5.025a1.26 1.26 0 0 0-.889-.359h-.315v6.563H20V8.75zm-5 11.539v3.54h3.593l8.42-8.347-3.535-3.48-8.478 8.287zm14.61-9.214-1.659-1.63a1.341 1.341 0 0 0-1.875 0l-1.423 1.4 3.535 3.48 1.422-1.4a1.294 1.294 0 0 0 0-1.85z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)

export default Approval
