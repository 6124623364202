import Modal from '@components/ui/Modal'
import { useSystemVersion } from '@lib/services/profile'
import { Col, ModalProps, Row } from 'antd'
import styled from 'styled-components'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const SystemVersionModal = (props: ModalProps) => {
  const { data } = useSystemVersion()
  const { t } = useTranslation('system')

  const labelColSpan = 6

  return (
    <Modal
      {...props}
      maskClosable
      footer={null}
      closeIcon={
        <CloseCircleOutlined
          onClick={props.onCancel}
          style={{ fontSize: '22px', color: '#000' }}
        />
      }
      title={<h2>{t('info')}</h2>}>
      <StyledRow>
        <Col span={labelColSpan}>{t('name')}:</Col>
        <Col>{data?.siteName}</Col>
      </StyledRow>
      <StyledRow>
        <Col span={labelColSpan}>{t('url')}:</Col>
        <Col>{data?.serverUrl}</Col>
      </StyledRow>
      {data?.companyCode && data.companyCode !== 'systemAdmin' && (
        <StyledRow>
          <Col span={labelColSpan}>{t('companyCode')}:</Col>
          <Col>{data?.companyCode}</Col>
        </StyledRow>
      )}
      <StyledRow>
        <Col span={labelColSpan}>{t('version')}:</Col>
        <Col>{data?.version}</Col>
      </StyledRow>
      <StyledRow>
        <Col span={labelColSpan}>{t('commit hash')}:</Col>
        <Col>{data?.apiCommitHash}</Col>
      </StyledRow>
    </Modal>
  )
}

const StyledRow = styled(Row)`
  margin-bottom: 10px;
  font-size: 16px;

  > div:nth-child(even) {
    font-weight: bold;
    font-size: 1.1em;
  }
`

export default SystemVersionModal
