import { indexBy, prop } from 'ramda'
import React from 'react'
import { useQuery } from 'react-query'
import { MemberRole } from './auth.types'

const useMemberRole = () => {
  const { data } = useQuery<MemberRole[]>(['/memberRole/defList'], {
    cacheTime: Infinity,
    select: (res: any) => prop('data', res),
  })

  const {
    AdminMemberRole,
    CompanyMemberRole,
    FullMemberRolesById,
    EntryRemindMemberRole,
  } = React.useMemo(() => {
    const AdminMemberRole = data?.filter(r => [1, 2].includes(r.id))
    const CompanyMemberRole = data?.filter(r => ![1, 2].includes(r.id))
    const FullMemberRolesById = indexBy(prop('id'), data || [])
    // 輸入提醒的角色
    const EntryRemindMemberRole = data?.filter(r => ![2].includes(r.id))

    return {
      AdminMemberRole,
      CompanyMemberRole,
      FullMemberRolesById,
      EntryRemindMemberRole,
    }
  }, [data])

  return {
    FullMemberRole: data,
    AdminMemberRole,
    CompanyMemberRole,
    EntryRemindMemberRole,
    FullMemberRolesById,
    /**
     * 稽核人員
     */
    AuditRoleId: 2,
    OrganizationManagerRoleId: 5,
    DepartmentManagerRoleId: 6,
  }
}

export default useMemberRole
