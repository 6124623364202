import { SVGProps } from 'react'

const History = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M30 14.972A15 15 0 0 1 5.587 26.679a1.451 1.451 0 0 1-.111-2.154l.681-.681a1.454 1.454 0 0 1 1.929-.12 11.13 11.13 0 1 0-.71-16.833l3.07 3.07a.968.968 0 0 1-.684 1.652H.968A.968.968 0 0 1 0 10.645V1.853a.968.968 0 0 1 1.652-.684l2.986 2.986A15 15 0 0 1 30 14.972zm-10.942 4.766.594-.764a1.452 1.452 0 0 0-.254-2.036l-2.46-1.916V8.71a1.452 1.452 0 0 0-1.452-1.452h-.968c-.802 0-1.452.65-1.452 1.452v8.2l3.956 3.077a1.452 1.452 0 0 0 2.036-.25z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)

export default History
