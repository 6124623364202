import React from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Row, Form, Spin } from 'antd'
import styled from 'styled-components'
import { PlusCircleOutlined } from '@ant-design/icons'
import Hr from '@components/ui/Hr'
import Button from '@components/ui/Button'
import Layout, { H3 } from '@components/ui/Layout'
import { useAssessItemList, useAddAssessItem } from '@/assessment'
import type { IAssessItem } from '@/assessment'
import { MutationOptions } from '@/services'
import { ActionTypes } from '@lib/auth'
import useRouteAuth from '@/routes/useRouteAuth'
import Modals from 'src/components/Modals'
import ItemForm from './ItemForm'
import { uuid } from '@lib/utils/webHelper'
import { any, compose, propEq, values } from 'ramda'
import Modal from '@components/ui/Modal'
import { useTranslation } from 'react-i18next'

const AssessItemsManagement = React.memo(() => {
  const history = useHistory()
  const [form] = Form.useForm()

  const [, checkIsAuthenticated] = useRouteAuth()

  const [isShowDeleteModal, setShowDeleteModal] = React.useState(false)

  const { data, refetch } = useAssessItemList()
  const [itemData, setItemData] = React.useState<any[]>([])
  const [delItem, setDelItem] = React.useState<IAssessItem>()
  const [errorMessage, setErrorMessage] = React.useState('')

  const mutationOptions: MutationOptions = {
    onSuccess: () => {
      refetch()
      history.goBack()
    },
  }

  const [creating, handleAdd] = useAddAssessItem(mutationOptions)

  const handleSave = () => {
    form
      .validateFields()
      .then(values => {
        setErrorMessage('')
        const params = Object.values(values)
        handleAdd({ items: params })
      })
      .catch(err => {
        console.log('err', err)
        setErrorMessage('請填寫所有欄位。')
      })
  }

  const handleDeleteItem = React.useCallback(() => {
    setItemData((prev: any) => {
      return prev.filter((i: any) => i.uuid !== delItem?.uuid)
    })
    setShowDeleteModal(false)
  }, [delItem])

  const handleDeleteModal = (val: any) => {
    setDelItem(val)
    setShowDeleteModal(true)
  }

  const hasUnsaveItem = () =>
    compose(any(propEq('isNewRow', true)), values)(form.getFieldsValue())

  React.useEffect(() => {
    const onConfirmRefresh = (event: BeforeUnloadEvent) => {
      if (hasUnsaveItem()) {
        event.preventDefault()
        return (event.returnValue = true)
      }
    }
    window.addEventListener('beforeunload', onConfirmRefresh)
    return () => {
      window.removeEventListener('beforeunload', onConfirmRefresh)
    }
  }, [])

  React.useEffect(() => {
    if (!data) {
      return
    }

    setItemData(data)
  }, [data])

  const creatable = React.useMemo(
    () =>
      checkIsAuthenticated(
        ActionTypes.CREATABLE,
        `${history.location.pathname.replace('/items', '')}`
      ),
    []
  )

  const { t } = useTranslation()

  return (
    <>
      <Spin spinning={creating}>
        <LayoutStyled>
          <Row justify="space-between" align="middle">
            <Col className="flex ">
              <H3 className="mr-15 ">{t('common:assessment of each item')}</H3>
            </Col>
            <Col>
              <Button
                color="grey"
                className="mr-15"
                onClick={() => {
                  if (hasUnsaveItem()) {
                    Modal.confirm({
                      title: '有尚未儲存的項目，是否確定要離開?',
                      onOk: () => {
                        history.goBack()
                      },
                    })
                  } else {
                    history.goBack()
                  }
                }}>
                {creatable ? t('common:Cancel') : '返回'}
              </Button>
              {creatable && (
                <Button color="primary" onClick={handleSave}>
                  {t('common:Save')}
                </Button>
              )}
            </Col>
          </Row>
          {!!errorMessage && (
            <Row>
              <p className="text-danger">{errorMessage}</p>
            </Row>
          )}
          <Hr type="solid" />
          <Form form={form}>
            {itemData?.map((v: any, i: number) => (
              <ItemForm
                sequence={i + 1}
                item={v}
                onDelete={handleDeleteModal}
              />
            ))}
          </Form>
          {creatable && (
            <Button
              className="ml-24 mb-15"
              icon={<PlusCircleOutlined />}
              onClick={() =>
                setItemData(prev => [
                  ...prev,
                  {
                    uuid: uuid(),
                    name: '',
                    details: [],
                    isNewRow: true,
                  } as any,
                ])
              }>
              評估項目
            </Button>
          )}
        </LayoutStyled>
      </Spin>
      <Modals.ConfirmModal
        visible={isShowDeleteModal}
        title={delItem?.name ? `確認 ${delItem?.name}` : '刪除此項目'}
        onOk={handleDeleteItem}
        onCancel={() => setShowDeleteModal(false)}
      />
    </>
  )
})

const LayoutStyled = styled(Layout)`
  border-radius: 10px;
  background-color: #fff;
  padding: 13px 14px;
`

export default AssessItemsManagement
