import { SVGProps } from 'react'

const Warehouse = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M23.625 20.077H6.394a.374.374 0 0 0-.375.372l-.005 2.227c0 .205.168.37.375.37h17.236a.374.374 0 0 0 .373-.37v-2.223a.37.37 0 0 0-.108-.265.378.378 0 0 0-.265-.11zm0 4.453H6.379a.374.374 0 0 0-.375.372L6 27.129c0 .204.168.369.375.37h17.25a.374.374 0 0 0 .373-.37v-2.227a.374.374 0 0 0-.373-.37v-.002zm0-8.906H6.399a.374.374 0 0 0-.375.37v2.228c0 .205.168.371.375.371h17.226a.374.374 0 0 0 .373-.371v-2.226a.374.374 0 0 0-.373-.372zm4.992-6.449L15.862 3.92a2.278 2.278 0 0 0-1.73 0L1.383 9.176A2.231 2.231 0 0 0 0 11.231V27.13c.001.204.169.369.375.37h3.75a.374.374 0 0 0 .375-.372V15.624a1.512 1.512 0 0 1 1.528-1.485h17.943a1.512 1.512 0 0 1 1.529 1.485v11.505c0 .205.168.37.375.371h3.75a.374.374 0 0 0 .375-.371V11.23a2.232 2.232 0 0 0-1.384-2.055z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)

export default Warehouse
