import Button from './Button'
import styled from 'styled-components'

export default styled(Button)`
  &.actived {
    font-weight: bold;
    color: ${p => p.theme.blue} !important;
  }
  :hover {
    background: rgba(201, 227, 248, 0.5) !important;
  }
`
