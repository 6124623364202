import Button from '@components/ui/Button'
import React from 'react'
import styled from 'styled-components'
import { WarningOutlined } from '@ant-design/icons'
import Modal from '@components/ui/Modal'
import { useTranslation } from 'react-i18next'
import { Space } from 'antd'

type IConfirmLoginModalProp = {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

const ConfirmLoginModal = React.memo<IConfirmLoginModalProp>(props => {
  const { visible, onOk, onCancel } = props

  const { t } = useTranslation('common')

  return (
    <ConfirmLoginModalStyled
      visible={visible}
      footer={
        <div className="flex justify-center">
          <Space size={96}>
            <Button color="secondary" onClick={onCancel}>
              {t('cancelLogin')}
            </Button>
            <Button color="primary" onClick={onOk}>
              {t('okLogin')}
            </Button>
          </Space>
        </div>
      }
      centered
      closable={false}>
      <div className="flex justify-center items-center text-lg">
        <WarningOutlined
          style={{ color: '#f8a204', fontSize: '3rem', marginRight: '15px' }}
        />
        {t('confirmLoginMessage')}
      </div>
    </ConfirmLoginModalStyled>
  )
})

const ConfirmLoginModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-body {
    background-color: #e7f3fd;
    padding: 60px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .ant-modal-footer {
    background-color: #e7f3fd;
  }
`

export default ConfirmLoginModal
