import { SVGProps } from 'react'

const User = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M0 2.667v18.666A2.667 2.667 0 0 0 2.667 24h18.666A2.675 2.675 0 0 0 24 21.333V2.667A2.675 2.675 0 0 0 21.333 0H2.667A2.667 2.667 0 0 0 0 2.667zM16 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM4 18.667c0-2.667 5.333-4.134 8-4.134s8 1.467 8 4.134V20H4v-1.333z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)
export default User
