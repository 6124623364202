import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useGetSSOLoginURL, useVerifySSOLogin } from '@/sso'
import Modal from '@components/ui/Modal'
import { Spin } from 'antd'
import { useMineInfo } from '@lib/auth/useMineInfo'
import Button from '@components/ui/Button'
import Store from 'store'
import useCompanyUrl from '@lib/hooks/useCompanyUrl'
import { COMPANY_ROOT_ROUTE } from '@lib/routes'
import { useTranslation } from 'react-i18next'

const Verify = () => {
  const location = useLocation()
  const { t } = useTranslation('common')

  const companyUrl = useCompanyUrl()

  const history = useHistory()

  const { refetch: getMineInfo } = useMineInfo({
    onSuccess: () => {
      history.push(companyUrl.urlReplacer(COMPANY_ROOT_ROUTE))
    },
    onError: () => {
      setVerifyError(true)
    },
  })

  const [isVerifyError, setVerifyError] = React.useState(false)

  const { refetch: redirectToLoginURL } = useGetSSOLoginURL({
    onSuccess: (url: string) => {
      window.location.href = url
    },
  })

  const [, verify] = useVerifySSOLogin()

  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get('lightID')) {
      verify(
        {
          lightID: params.get('lightID'),
        },
        {
          onSuccess: (response: any) => {
            setVerifyError(false)
            Store.set(`sctc_token`, response.token)
            getMineInfo()
          },
          onError: err => {
            setVerifyError(true)
          },
        }
      )
    } else {
      redirectToLoginURL()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      <Modal visible centered footer={null} closable={false}>
        <div className="text-center">
          {isVerifyError ? (
            <>
              <div className="text-danger font-bold text-lg mb-10">
                {t('verifyFailed')}
              </div>
              <Button onClick={() => redirectToLoginURL()}>
                {t('verifyAgain')}
              </Button>
            </>
          ) : (
            <>
              <Spin />
              <label className="mx-10 font-bold text-lg">
                {t('verifyInprocess')}
              </label>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

export default Verify
