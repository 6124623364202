const Maintain = ({ ...props }) => {
  return (
    <i className="icon-maintain" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20">
        <path
          fill="#878787"
          d="M4.803 1.494c2.322-.983 5.034-.48 6.848 1.334 1.692 1.692 2.244 4.168 1.51 6.378-.052.158 5.62 5.603 5.62 5.603.292.293.292.768 0 1.06L15.87 18.78c-.293.293-.768.293-1.061 0l-5.182-5.182-.428-.427c-1.276.507-4.692.159-6.371-1.52C1.015 9.837.511 7.125 1.494 4.803c.208-.49.845-.614 1.221-.238l2.392 2.39 1.506-.342.34-1.497-2.388-2.4c-.376-.377-.252-1.014.238-1.222zM10.59 3.89C9.212 2.51 7.19 2.369 6.532 2.56l1.65 1.65.127.127c.183.182.259.446.201.697l-.54 2.37c-.064.282-.283.501-.564.565l-2.371.54c-.251.058-.697-.2-.697-.2L2.551 6.54c-.216 1.455.252 2.964 1.338 4.05 1.377 1.377 3.438 1.76 5.201 1.013.281-.12.607-.056.823.16l5.166 5.167.278.277 1.819-1.854-5.413-5.44c-.216-.216-.28-.542-.16-.823.747-1.763.364-3.824-1.013-5.201z"/>
      </svg>
    </i>
  )
}

export default Maintain
