import React, { useState } from 'react'
import styled from 'styled-components'

import { UnorderedListOutlined } from '@ant-design/icons'
import useModalVisible from '@lib/hooks/useModalVisible'
import Button from '@components/ui/Button'
import Checkbox from '@components/ui/Checkbox'
import Hr from '@components/ui/Hr'
import Modal from '@components/ui/Modal'
import { useOrganizationFullList } from '@lib/options'
import { useTranslation, Trans } from 'react-i18next'

type Props = {
  onChange?: (values: any[]) => any
  value?: any[]
  defaultSelectAll?: boolean
}

const OrganizationListModal = React.memo<Props>(
  ({ onChange, value = [], defaultSelectAll = false, ...props }: Props) => {
    const [visible, handleVisible] = useModalVisible()

    const [checkedList, setCheckedList] = useState<any[]>(value)
    const [checkAll, setCheckAll] = useState(false)

    const { data = [] } = useOrganizationFullList()
    const [indeterminate, setIndeterminate] = useState(
      !!checkedList.length && checkedList.length < data.length
    )

    const onCheckAllChange = (e: any) => {
      setCheckedList(e.target.checked ? data.map(i => i.id) : [])
      setIndeterminate(false)
      setCheckAll(e.target.checked)
    }

    const handleChange = (val: any) => {
      setCheckedList(val)
      setIndeterminate(!!val.length && val.length < data.length)
      setCheckAll(val.length === data.length)
    }

    const handleOk = React.useCallback(() => {
      handleVisible()
      onChange && onChange(checkedList)
    }, [checkedList]) //eslint-disable-line

    const handleCancel = () => {
      handleVisible()
      setCheckedList(value)
    }

    React.useEffect(() => {
      if (data.length === 0) {
        return
      }
      if (!defaultSelectAll) {
        return
      }
      setCheckedList(data.map(i => i.id))
      setIndeterminate(false)
      setCheckAll(true)
      onChange && onChange(data.map(i => i.id))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const { t } = useTranslation()

    return (
      <>
        <div className="flex items-center">
          <Button
            type="icon"
            icon={<UnorderedListOutlined />}
            iconPosition="right"
            onClick={handleVisible}>
            {t('formItems:select organization')}
          </Button>
          {checkedList.length > 0 && (
            <span className="ml-24" style={{ fontSize: 16 }}>
              <Trans
                i18nKey="formItems:count selected organization"
                values={{ count: checkedList.length }}
                components={[
                  <span style={{ color: '#0075d3' }} className="px-4" />,
                ]}
              />
            </span>
          )}
        </div>
        <StyledModal
          width={513}
          visible={visible}
          closable={false}
          title={
            <>
              <div className="flex justify-between items-center">
                <div>
                  <label className="text-black mr-10">
                    {t('formItems:select organization')}
                  </label>
                </div>
                <div>
                  <Button
                    color="grey"
                    className="mr-10"
                    style={{ minWidth: 'fit-content' }}
                    onClick={handleCancel}>
                    {t('common:Cancel')}
                  </Button>
                  <Button
                    color="primary"
                    style={{ minWidth: 'fit-content' }}
                    onClick={handleOk}>
                    {t('common:Save')}
                  </Button>
                </div>
              </div>
              <Hr />
            </>
          }
          footer={null}
          {...props}>
          <Item>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}>
              {t('formItems:organization list')}
            </Checkbox>
          </Item>

          <Checkbox.Group
            className="w-full"
            value={checkedList.map(i => i)}
            onChange={handleChange}>
            {data.map(i => (
              <Item>
                <Checkbox key={i.id} value={i.id}>
                  {i.shortName}
                </Checkbox>
              </Item>
            ))}
          </Checkbox.Group>
        </StyledModal>
      </>
    )
  }
)

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border: 1px solid ${p => p.theme.darkGrey};
  }
  .ant-modal-body {
    padding: 10px 30px 80px;
  }
`
const Item = styled.div`
  height: 39px;
  width: 100%;
  border-bottom: 1px dashed #b8b8b8;
  display: inline-flex;
  align-items: center;

  .ant-checkbox-wrapper {
    color: #000;
    font-size: 18px;
  }
`
export default OrganizationListModal
