import {
  find,
  propEq,
  forEach,
  addIndex,
  has,
  map,
  isEmpty,
  ifElse,
  identity,
  either,
  compose,
  not,
  is,
  isNil as RisNil,
  path as Rpath,
  head,
  slice,
} from 'ramda'
import moment from 'moment'
import { isMobile } from '@/utils/browser-detect'
import Big from 'big.js'
import envStore from '@/env'

export function queryString(search: string): { [key: string]: any } {
  if (!search) {
    search = window.location.search
  }

  const params = new URLSearchParams(search)

  let result: object | any = {}

  params.forEach((value: string, key: string) => (result[key] = value))

  return result
}

export function uuid() {
  let d = Date.now()
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now()
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export function isUUID(uuid: string) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    uuid
  )
}

export function toBase64(file: File) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.addEventListener('load', () => resolve(fileReader.result))
    fileReader.onerror = reject
    fileReader.readAsDataURL(file)
  })
}

export function selectFile({
  accept = '*',
  callback,
}: {
  accept?: string
  callback: (files: FileList | null) => any
}) {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('class', 'hidden')
  input.setAttribute('accept', accept)
  input.addEventListener('change', () => callback(input.files))
  input.click()
}

export const findValue = (value?: number | string, field = 'pid') =>
  find(propEq(field, value))

export const forEachIndexed: any = addIndex(forEach)
export const mapIndexed: any = addIndex(map)

export const isNil = either(isEmpty, RisNil)

export const isNotNil = compose(not, isNil)

export const hasValue = ifElse(isNil, identity, has('value'))

export const hasToken = ifElse(isNil, identity, has('token'))

export const hasPath = (path: Array<string | number>) =>
  compose(not, isNil, Rpath(path))

export const getPickerValue = ifElse(is(Array), head, identity)

export const toMoment = (str: moment.Moment | string | number | undefined) => {
  const format = 'YYYY-MM-DD HH:mm:ss'

  const converter = isMobile() ? () => moment(str, format) : () => moment(str)

  if (!str) {
    return undefined
  }

  if (!converter().isValid()) {
    return undefined
  }

  if (moment.isMoment(str)) {
    return str
  }

  return converter()
}

export const momentToString = (
  date: moment.Moment | undefined | string | number,
  format: string = 'YYYY/MM/DD, HH:mm'
) => {
  return moment.isMoment(date) && date.isValid()
    ? date.format(format)
    : is(String, date) || is(Date, date) || is(Number, date)
    ? toMoment(date) && toMoment(date)?.isValid()
      ? (toMoment(date) as moment.Moment).format(format)
      : ''
    : ''
}

export const getRangeDate = (
  date: [moment.Moment, moment.Moment],
  dateFormat?: string
) => {
  const [startDate, stopDate] = date || [undefined, undefined]

  return [
    startDate
      ? dateFormat
        ? startDate.format(dateFormat)
        : startDate.valueOf()
      : undefined,
    stopDate
      ? dateFormat
        ? stopDate.format(dateFormat)
        : stopDate.valueOf()
      : undefined,
  ]
}

export const toRangeDateObj = (
  date: [moment.Moment | undefined | null, moment.Moment | undefined | null],
  rangeKey?: [string, string]
) => {
  const [startAt, endAt] = date || [undefined, undefined]
  const [startKey, endKey] = rangeKey || ['startAt', 'endAt']

  return {
    [startKey]: startAt ? startAt.startOf('day').valueOf() : null,
    [endKey]: endAt ? endAt.endOf('day').valueOf() : null,
  }
}

export const toThousandSeparator_v0 = (number: number | string | undefined) => {
  if (number && (typeof number === 'number' || isNaN(number as any))) {
    return number.toLocaleString()
  }
  return number
}

/**
 * 排放當量小數格式化與千分位
 * @param formatValue
 * @returns
 */
export const toThousandSeparator = (formatValue: number | undefined) => {
  return numberFractionDigitsFormat(envStore.decimalNumber)(formatValue)
}

export const numberFractionDigitsFormat =
  (digits: number) => (formatValue: number | undefined) => {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: digits,
    })
    return not(isNil(formatValue))
      ? typeof formatValue === 'number'
        ? formatter.format(formatValue as number)
        : undefined
      : undefined
  }

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const downloadFile = ({
  blob,
  filename,
  extension = 'xlsx',
}: {
  blob: Blob
  filename?: string
  extension?: string
}) => {
  const a = document.createElement('a')
  var binaryData = []
  binaryData.push(blob)

  let objectUrl = window.URL.createObjectURL(new Blob(binaryData))

  a.href = objectUrl
  a.download = `${moment().format('YYYYMMDDHHMMss')}_${filename}.${extension}`
  a.click()
}

export function downloadCSV({
  text,
  filename,
}: {
  text: string
  filename: string
}) {
  const dataStr = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute(
    'download',
    `${moment().format('YYYYMMDDHHMMss')}_${filename}.csv`
  )
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

export function downloadAsJson({
  json,
  filename,
}: {
  json: object
  filename: string
}) {
  const dataStr =
    'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json))
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', filename + '.json')
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

export function getDiffHour(
  start: moment.Moment | undefined,
  end?: moment.Moment
) {
  if (!start || !end) {
    return 0
  }

  let diff = (end.toDate().getTime() - start.toDate().getTime()) / 1000
  diff /= 60 * 60

  return diff
}

export function formatDate(
  value: string | number | moment.Moment | Date,
  format = 'YYYY.MM.DD'
) {
  if (!value) {
    return value
  }

  return moment(value).format(format)
}

const getDatePart = (date: Date) => {
  const M = date.getMonth() + 1 // getMonth() is zero-based
  const dd = date.getDate()
  const hh = date.getHours()
  const mm = date.getMinutes()
  const ss = date.getSeconds()

  return [
    date.getFullYear(),
    (M > 9 ? '' : '0') + M,
    (dd > 9 ? '' : '0') + dd,
    (hh > 9 ? '' : '0') + hh,
    (mm > 9 ? '' : '0') + mm,
    (ss > 9 ? '' : '0') + ss,
  ]
}

export function dateToYYYYMMDD(
  date?: Date | number | moment.Moment,
  sep: string = '-'
) {
  if (!date) {
    return date
  }

  let _date = date

  if (typeof date === 'number') {
    _date = new Date(date)
  }

  if (moment.isMoment(date)) {
    _date = date.toDate()
  }

  const [yyyy, mm, dd] = getDatePart(_date as Date)

  return [yyyy, mm, dd].join(sep)
}

export function dateToYYYYMMDDHHMM(
  date?: Date | moment.Moment,
  sep: string = '-'
) {
  if (!date) {
    return date
  }

  let _date = moment.isMoment(date) ? date.toDate() : moment(date).toDate()

  const [yyyy, MM, dd, hh, mm] = getDatePart(_date)

  return `${[yyyy, MM, dd].join(sep)} ${[hh, mm].join(':')}`
}

export const getYearRange = (yr: number, format = 'YYYY-MM-DD HH:mm:ss') => {
  const start = moment(`${yr}-01-01`).format(format)
  const end = moment(`${yr}`).endOf('year').format(format)

  return [start, end]
}

export const getMonthRange = (
  yearMonth: string,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  const start = moment(yearMonth).startOf('month').format(format)
  const end = moment(yearMonth).endOf('month').format(format)

  return [start, end]
}

export const convertMS = (milliseconds: number) => {
  let year, day, hour, minute, seconds

  seconds = Math.floor(milliseconds / 1000)
  minute = Math.floor(seconds / 60)
  seconds = seconds % 60

  hour = Math.floor(minute / 60)
  minute = minute % 60
  day = Math.floor(hour / 24)
  hour = hour % 24

  return {
    day: day,
    hour: hour,
    minute: minute,
    seconds: seconds,
  }
}

export function getNumberFromString(str?: string) {
  if (!str) {
    return str
  }
  return str.replace(/[^\d.]/g, '')
}

export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min)
}

/**
 * 搜尋日期 YYYY-MM-DD 00:00:00 ~ 23:59:59
 * @returns [start,end]
 */
export const getRangeDateToMS = (
  date: [moment.Moment | undefined | null, moment.Moment | undefined | null],
  rangeKey?: [string, string]
) => {
  const [startAt, endAt] = date || [undefined, undefined]
  const [startKey, endKey] = rangeKey || ['start', 'end']

  const start = startAt ? startAt.startOf('date').valueOf() : null
  const end = endAt ? endAt?.endOf('date').valueOf() : null

  return {
    [startKey]: start,
    [endKey]: end,
  }
}

export const disabledFutureDatePicker = (current: moment.Moment) =>
  current && current > moment().endOf('day')

export const truncateText = (v: string, len = 20) =>
  v ? (v.length > len ? `${slice(0, len, v)}...` : v) : null

export const BigNumber = (value: any) => {
  if (isNil(value) || isNaN(value)) {
    return new Big(0)
  }
  return new Big(value)
}
