import { SVGProps } from 'react'

const Cate6 = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M24.818 2.728h-5.7a4.075 4.075 0 0 0-7.69 0h-5.7A2.735 2.735 0 0 0 3 5.455v21.818A2.735 2.735 0 0 0 5.727 30h19.091a2.735 2.735 0 0 0 2.727-2.727V5.455a2.735 2.735 0 0 0-2.727-2.727zm-9.545 0a1.364 1.364 0 1 1 0 2.727 1.364 1.364 0 0 1 0-2.727zm9.545 24.545H5.728V5.455h2.726v4.09h13.637v-4.09h2.727v21.818z"
      fill="#44596C"
      fillRule="nonzero"
    />
  </svg>
)

export default Cate6
