import { SVGProps } from 'react'

const Industry = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="m26.77 10.737-7.473 4.693v-3.62a1.27 1.27 0 0 0-.669-1.114 1.304 1.304 0 0 0-1.312.041L9.844 15.43V5.022a1.28 1.28 0 0 0-1.29-1.272H2.54A1.28 1.28 0 0 0 1.25 5.022v21.206c0 .337.136.66.377.9.242.238.57.372.912.372H27.46c.342 0 .67-.134.912-.373.241-.238.377-.562.377-.9V11.81a1.27 1.27 0 0 0-.67-1.115 1.304 1.304 0 0 0-1.31.042z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)

export default Industry
