import { SVGProps } from 'react'

const InvoiceDollar = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M25.84 6.152 20.104.41a1.405 1.405 0 0 0-1-.41h-.354v7.5h7.5v-.357a1.4 1.4 0 0 0-.41-.991zm-8.965 1.817V0H5.156A1.4 1.4 0 0 0 3.75 1.406v27.188A1.4 1.4 0 0 0 5.156 30h19.688a1.4 1.4 0 0 0 1.406-1.406V9.375h-7.969a1.41 1.41 0 0 1-1.406-1.406zM7.5 4.219c0-.259.21-.469.469-.469h4.687c.259 0 .469.21.469.469v.938a.469.469 0 0 1-.469.469H7.969a.469.469 0 0 1-.469-.469v-.938zm0 4.688v-.938c0-.259.21-.469.469-.469h4.687c.259 0 .469.21.469.469v.938c0 .259-.21.469-.469.469H7.969a.469.469 0 0 1-.469-.47v.001zm8.438 15.462v1.413c0 .259-.21.469-.469.469h-.937a.469.469 0 0 1-.469-.469v-1.424a3.355 3.355 0 0 1-1.838-.665.469.469 0 0 1-.033-.711l.688-.657a.481.481 0 0 1 .594-.043c.225.142.485.218.751.218h1.647a.737.737 0 0 0 .691-.773.765.765 0 0 0-.514-.746l-2.637-.791a2.652 2.652 0 0 1-1.85-2.542 2.609 2.609 0 0 1 2.5-2.641v-1.413c0-.259.21-.469.469-.469h.938c.259 0 .469.21.469.469v1.423a3.351 3.351 0 0 1 1.838.665.469.469 0 0 1 .033.711l-.688.657a.481.481 0 0 1-.594.043 1.406 1.406 0 0 0-.751-.218h-1.648a.737.737 0 0 0-.691.773.765.765 0 0 0 .514.746l2.637.791a2.652 2.652 0 0 1 1.85 2.542 2.609 2.609 0 0 1-2.5 2.641v.001z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)

export default InvoiceDollar
