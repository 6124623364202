const primary = '#c9e3f8'
const primary100 = 'rgba(0, 116, 168, 0.1)'
const secondary = '#e6edf1'
const info = '#5E9CE5'
const darkGrey = '#707070'
const darkGrey500 = 'rgba(74, 74, 74, 0.5)'
const darkBlue = '#44596c'
const whisper = '#e4e4e4'
const grey = '#efefef'
const lightBlue = '#b7d6e4'
const silverGrey = '#b8b8b8'
const light = '#fff'
const beige = '#fff1b8'
const green = '#019601'

/**
 * @return { [key: string]: any}
 */
const theme = {
  background: '#e7f3fd',
  adminBackground: '#dfe7bc',
  green,
  blue100: '#e7f2fd',
  blue150: 'rgba(183, 220, 255, 0.15)',
  blue200: 'rgba(183, 220, 255, 0.2)',
  blue300: 'rgba(183, 220, 255, 0.3)', // b7dcff
  blue600: 'rgba(183, 220, 255, 0.6)',
  blue700: '#b7dcff',
  darkBlue,
  cyan: '#0ab1c1',
  blue: '#0075d3',
  danger: '#d74c4c',
  dark: '#222',
  dark100: 'rgba(0, 0, 0, 0.1)',
  dark300: 'rgba(0, 0, 0, 0.3)',
  dark500: 'rgba(0, 0, 0, 0.5)',
  darkGrey: darkGrey,
  grey,
  grey700: 'rgba(74, 74, 74, 0.7)',
  greyF9: '#f9f9f9',
  silverGrey,
  info: info, // (16, 94, 166)
  info200: 'rgba(16, 94, 166, 0.2)',
  info500: 'rgba(16, 94, 166, 0.5)',
  info900: '#4098ef',
  lightDark: '#333',
  lightGrey: '#e7e7e7',
  lightPink: '#ffeaea',
  light: light,
  primary: primary,
  red100: '#ffdad6',
  red200: '#e17567',
  red500: 'rgba(243, 76, 57, 0.5)',
  red: '#ff0000',
  crimson: '#ce2512',
  secondary,
  warning: primary,
  yellow200: '#ffcc2b',
  yellow100: beige,
  yellow: '#ffc247',
  btn: {
    text: '#000',
    bg: primary,
    bgHover: '#7894ab',
    outlineHover: primary100,
    linkHover: '#40a9ff',
    linkColor: '#1890ff',
    info: info,
    infoHover: 'rgba(94, 156, 229, 0.5)',
    border: '#707070',
  },
  modal: {
    mask: 'rgba(0, 44, 86, 0.7)',
    maskDarken: 'rgba(0, 44, 86, 0.9)',
    border: info,
    title: primary,
  },

  gray88: '#e0e0e0',
  whisper: whisper,
  table: {
    headBg: 'transparent',
    head: '#000',
    shape: 'none',
    bg: '#fff',
    bgHover: '#fffcf1',
    border: whisper,
    borderBottom: darkGrey,
    borderBottomHover: '#5e9ce5',
    borderHover: 'transparent',
    spacing: 0,
  },
  slider: '#c0973f',
  sliderBg: '#c0973f',
  pagination: '#000',
  block: {
    text: grey,
    section: darkGrey,
    title: darkGrey,
    content: '#f4f3f3',
    borderBottom: info,
    borderRight: 'transparent',
  },
  sidemenu: primary,
  calendarIcon: grey,
  searchIcon: primary,
  schedule: {
    searchBtn: darkGrey,
    searchBtnBg: primary,
    searchBtnIcon: darkGrey,
    text: info,
    checkboxDisable: darkGrey,
  },
  font: {
    primary,
    secondary: grey,
  },
  icon: {
    primary: darkGrey500,
    primaryHover: darkGrey,
    secondary: '#fff',
  },
  menu: {
    hoverBg: '#fff',
    activeBg: '#fff',
  },
  lightBlue,
  checkbox: {
    border: '#000',
    bg: '#000',
    text: '#000',
    color: '#fff',
    indeterminate: '#000',
  },
  darkGrey500,
  map: {
    rectangleOptions: {
      fillColor: 'rgba(255, 207, 110, 0.3)',
      strokeColor: '#b68116',
      strokeWeight: 2,
    },
  },
  tabs: {
    default: '#c0c0c0',
    active: primary,
    bg: '#fff',
  },
  tooltip: {
    color: '#4a4a4a',
    bg: '#fffcf1',
  },
  message: {
    color: darkGrey,
    bg: beige,
    fontSize: '16px',
    fontWeight: 500,
  },
  gamboge: '#b68116',
  menuInlineTooltip: {
    bg: '#fff',
    color: grey,
    active: primary,
  },
  group: {
    active: { bg: '#dff5ff' },
  },
  radio: {
    size: '20px',
    primary: '#284257',
    borderWidth: '3px',
    textSize: '20px',
  },
  report: {
    primary: '#073963',
  },
}

module.exports = theme
