// import React from 'react'
import ReactDOM from 'react-dom'
import renderer from '@/utils/renderer'
import { envStore } from '@/env'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'leaflet/dist/leaflet.css'
;(window as any).__webpack_nonce__ = envStore.cspNonce

const app = renderer()(App)

ReactDOM.render(app, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
