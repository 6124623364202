import { SVGProps } from 'react'

const Database = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M27.5 4.286v2.678c0 2.361-5.879 4.286-13.125 4.286S1.25 9.325 1.25 6.964V4.286C1.25 1.925 7.129 0 14.375 0S27.5 1.925 27.5 4.286zm0 6.027v6.027c0 2.361-5.879 4.286-13.125 4.286S1.25 18.7 1.25 16.339v-6.026c2.82 1.942 7.981 2.846 13.125 2.846s10.305-.905 13.125-2.846zm0 9.375v6.027c0 2.36-5.879 4.285-13.125 4.285S1.25 28.075 1.25 25.714v-6.026c2.82 1.942 7.981 2.846 13.125 2.846s10.305-.905 13.125-2.846z"
        fill="#44596C"
        fillRule="nonzero"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)

export default Database
