import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import commonEN from './en/common.json'
import commonZhTW from './zhTW/common.json'
import commonZhCN from './zhCN/common.json'

import menuEN from './en/menu.json'
import menuZhTW from './zhTW/menu.json'
import menuZhCN from './zhCN/menu.json'

import alertZhTW from './zhTW/alert.json'
import alertZhCN from './zhCN/alert.json'

import formItemsZhTW from './zhTW/formItems.json'
import formItemsZhCN from './zhCN/formItems.json'

import tableColumnsZhTW from './zhTW/tableColumns.json'
import tableColumnsZhCN from './zhCN/tableColumns.json'

import reportZhTW from './zhTW/report.json'
import reportZhCN from './zhCN/report.json'

import systemZhTW from './zhTW/system.json'
import systemEN from './en/system.json'
import systemZhCN from './zhCN/system.json'

// the translations
const resources = {
  en_US: {
    common: commonEN,
    menu: menuEN,
    alert: alertZhTW,
    formItems: formItemsZhTW,
    tableColumns: tableColumnsZhTW,
    report: reportZhTW,
    system: systemEN,
  },
  zh_TW: {
    menu: menuZhTW,
    common: commonZhTW,
    alert: alertZhTW,
    formItems: formItemsZhTW,
    tableColumns: tableColumnsZhTW,
    report: reportZhTW,
    system: systemZhTW,
  },
  zh_CN: {
    menu: menuZhCN,
    common: commonZhCN,
    alert: alertZhCN,
    formItems: formItemsZhCN,
    tableColumns: tableColumnsZhCN,
    report: reportZhCN,
    system: systemZhCN,
  },
}

const lang = navigator.language.replace('-', '_') || 'en_US'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ['common', 'menu', 'formItems', 'alert', 'tableColumns'],
    defaultNS: 'common',
    // fallbackNS: ['common'],
    resources,
    lng: lang,
    fallbackLng: 'en_US',
    keySeparator: false, // we do not use keys in form messages.welcome
  })

export default i18n
