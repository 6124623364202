import { SVGProps } from 'react'

const Power = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#44596C"
        fillRule="nonzero"
        d="M11.25 0 0 15h11.25l-7.5 15L30 11.25H15L26.25 0z"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
)
export default Power
