import React from 'react'
import { isNil } from 'ramda'
import Store from 'store'
import history from '@/routes/history'
import type { User } from './auth.types'
import useComapnyCode from '@/hooks/useComapnyCode'

export enum ActionTypes {
  DELETABLE = 'deletable',
  UPDATABLE = 'updatable',
  CREATABLE = 'creatable',
  READABLE = 'readable',
}

export type LogoutFn = (params?: { onSuccess?: () => any }) => any

export type AuthContextType = {
  logout: LogoutFn
  isAuthenticated: boolean
  user: User
  setUser: React.Dispatch<React.SetStateAction<User | null>>
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
}

export const AuthContext = React.createContext<AuthContextType>({
  isAuthenticated: false,
  user: {} as User,
  logout: () => ({}),
  setUser: (value: React.SetStateAction<User | null>) => ({}),
  setIsAuthenticated: (value: React.SetStateAction<boolean>) => ({}),
})

const _user = Store.get('sctc_user')
const _token = Store.get('sctc_token')

export function AuthProvider(props: any) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    !isNil(_token) && !isNil(_user)
  )
  const [user, setUser] = React.useState<User | null>(_user)

  const comapnyCode = useComapnyCode()

  const logout = React.useCallback(
    ({ onSuccess } = {}) => {
      const _currentUser = user
      Store.remove('sctc_token')
      Store.remove('sctc_user')
      setUser(null)
      setIsAuthenticated(false)
      if (_currentUser?.isCompanyAccount) {
        history.push(`/companies/${comapnyCode}`)
      } else {
        history.push('/')
      }

      if (onSuccess) {
        onSuccess()
      }
    },
    [comapnyCode, user]
  )

  React.useEffect(() => {
    if (user?.companyCode && user.companyCode !== comapnyCode) {
      // TODO: 是否要跳到 404 網頁
      // message({
      //   content: '您沒有權限瀏覽此網頁',
      //   type: 'error',
      // })
    }
  }, [comapnyCode, user])

  const value = React.useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      setUser,
      logout,
      user,
    }),
    [isAuthenticated, JSON.stringify(user)] // eslint-disable-line
  )

  return <AuthContext.Provider value={value} {...props} />
}

export function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export default useAuth
