import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menu, Layout } from 'antd'
import { any, is, propEq, takeLast } from 'ramda'
import styled from 'styled-components'
import cx from 'classnames'
import * as Icons from '@icons'
import { useProfile } from '@/services/profile'
import history from '@/routes/history'
import useViewport from '@/hooks/useViewport'
import {
  useRoutes,
  Route,
  RouteItem,
  STATIONARY_COMBUSTION_MANAGEMENT,
  ADMIN_COMPANY_LIST_MANAGEMENT,
} from '@/routes'
import * as routes from 'src/routes'
import useCompanyUrl from '@lib/hooks/useCompanyUrl'
import NodePathIcon from './NodePathIcon'
import useAuth from '@lib/auth'
import useWebSystemRole from '@lib/hooks/useWebSystemRole'
import { useTranslationConfig } from 'src/hooks/useTranslationConfig'
import envStore from '@/env'
const { has } = require('ramda')

const style = { height: '100%', borderRight: 0 }

function SideMenu() {
  const { t } = useTranslation()

  const location = useLocation()

  const { isMobile } = useViewport()

  const { _routes } = useRoutes(routes)

  const { profile } = useProfile()

  const companyUrl = useCompanyUrl()

  const systemRole = useWebSystemRole()

  const { user } = useAuth()

  const [defaultOpenKey, pathname] = location.pathname.split('/').slice(1)

  const [openKeys, setOpenKeys] = React.useState([])

  const handleOpenChange = (openKeys: React.Key[]) => {
    setOpenKeys(takeLast(1, openKeys) as any) // ramda對ts的支援似乎還不夠好...型別會錯
  }

  const handleTitleClick = (evt: React.MouseEvent<HTMLElement>) => {
    // handleModeChange(AlarmModes.ALL)()
    // setRectangle(null)
    history.push(`${evt.currentTarget.dataset.path}`)
  }

  const handleLogoClick = () => {
    // handleModeChange(AlarmModes.ALL)()
    // setRectangle(null)
    if (systemRole === 'Company') {
      history.push(companyUrl.urlReplacer(STATIONARY_COMBUSTION_MANAGEMENT))
    } else {
      history.push(ADMIN_COMPANY_LIST_MANAGEMENT)
    }
  }

  const handleMenuClick = () => {
    // handleModeChange(AlarmModes.ALL)()
  }

  const renderIcon = (item: RouteItem) => {
    let Icon: any = item.icon

    if (is(String, Icon) && has([Icon])(Icons)) {
      const Component = (Icons as any)[Icon]
      Icon = <Component />
    }
    if (is(Function, Icon)) {
      Icon = <Icon />
    }

    return Icon
  }

  const renderMenuItem = (item: RouteItem) => {
    if (!item.readable) {
      return
    }

    const to = companyUrl.urlReplacer(item.path)
    const className = item.isSubMenuIcon
      ? { className: 'flex items-center' }
      : {}

    return (
      <MenuItem key={item.id} disabled={item.disabled}>
        <span {...className}>
          {item.isSubMenuIcon && <NodePathIcon />}
          <Link to={to} onClick={handleMenuClick}>
            {item.title}
          </Link>
        </span>
      </MenuItem>
    )
  }

  const renderMenu = (data: any[]) =>
    data.map((item: Route) => {
      const hasReadableChild = any(propEq('readable', true))(
        (item.children as any) || []
      )
      if (!item.readable && !hasReadableChild) {
        return []
      }

      if (!item.isSubMenu) {
        const to = companyUrl.urlReplacer(item.path)

        return (
          <MainMenu
            key={item.id}
            className={cx({ 'pl-16': !isMobile })}
            icon={renderIcon(item)}
            disabled={item.disabled}>
            <Link to={to}>{item.title}</Link>
          </MainMenu>
        )
      }

      return item.children ? (
        <SubMenu
          key={item.id}
          title={
            <>
              {renderIcon(item)}
              {!isMobile && <span>{item.title}</span>}
            </>
          }
          disabled={item.disabled}>
          {item.children.map(renderMenuItem)}
        </SubMenu>
      ) : (
        <li
          key={item.id}
          data-path={item.path}
          onClick={item.disabled ? () => {} : handleTitleClick}
          className={
            item.disabled
              ? 'ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-selected ant-menu-submenu-disabled'
              : 'ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-selected'
          }>
          <div className="ant-menu-submenu-title l-flex is--middle">
            {renderIcon(item)}
            <span className="inline-flex">{item.title}</span>
          </div>
        </li>
      )
    })

  const { currentLang } = useTranslationConfig()

  const googleTranslateElementInit = React.useCallback(() => {
    // @ts-ignore
    new window.google.translate.TranslateElement(
      {
        pageLanguage: currentLang,
        includedLanguages: 'ar,en,zh-CN,ko',
      },
      'google_translate_element'
    )
  }, [currentLang])

  React.useEffect(() => {
    var addScript = document.createElement('script')
    addScript.setAttribute(
      'src',
      // '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      `${envStore.publicUrl}/js/google_translate_element.js`
    )
    document.body.appendChild(addScript)
    // @ts-ignore
    window.googleTranslateElementInit = googleTranslateElementInit
  }, [googleTranslateElementInit])

  return (
    <Sider collapsed={isMobile} width={160}>
      <Logo onClick={handleLogoClick} className="cursor-pointer">
        {/* <img src={`${publicUrl}/temp-logo@3x.png`} alt={profile.name} /> */}
        {!!profile && (
          <>
            <img src={profile.url} alt={profile.name} />
            {/* <div className="logo-text">{profile.name}</div> */}
          </>
        )}
      </Logo>
      {/* <div id="google_translate_element"></div> */}
      <StyledMenu
        mode="inline"
        openKeys={openKeys}
        defaultSelectedKeys={[pathname || defaultOpenKey]}
        defaultOpenKeys={[defaultOpenKey]}
        onOpenChange={handleOpenChange}
        style={style}>
        {renderMenu(_routes)}
      </StyledMenu>
    </Sider>
  )
}

const Sider = styled(Layout.Sider)`
  #google_translate_element {
    display: flex;
    justify-content: center;
  }

  &.ant-layout-sider {
    background-color: ${p => p.theme.background};
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .ant-skeleton-title {
    margin-left: 16px;
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 28px;
  }
`

const StyledMenu = styled(Menu)`
  &.ant-menu {
    height: auto !important;
    margin-top: 0px;
    margin-bottom: auto;
    background-color: ${p => p.theme.background};
  }

  &.ant-menu-inline-collapsed {
    .ant-menu-item {
      > a {
        float: right;

        .anticon {
          vertical-align: middle;
          margin-right: 0px;
        }
      }
    }
  }

  .ant-menu-item:hover,
  .ant-menu-item-selected {
    background: transparent !important;
    position: relative;
    z-index: 2;
    &:after {
      display: none;
    }
    &:before {
      position: absolute;
      display: block;
      content: ' ';
      width: 100%;
      height: 100%;
      background-color: #fff;
      left: 0;
      z-index: -1;
      border-radius: 0 17px 17px 0;
    }

    a {
      color: ${p => p.theme.darkBlue};
    }

    i.icon-media > svg > g {
      stroke: ${p => p.theme.darkBlue};
    }

    i[class*='icon'] > svg > path {
      fill: ${p => p.theme.darkBlue};
    }
  }

  .ant-menu-submenu-title {
    color: ${p => p.theme.darkBlue};
    font-size: 25px;
    font-weight: bold;

    i[class*='icon'] > svg > g > g {
      stroke: ${p => p.theme.darkBlue};
    }

    i[class*='icon'] > svg > path {
      fill: ${p => p.theme.darkBlue};
    }
  }

  .ant-menu-submenu-title:hover {
    color: ${p => p.theme.darkBlue};
    /* background: ${p => p.theme.menu.hoverBg}; */
  }

  &.ant-menu-inline {
    .ant-menu-item {
      width: auto;
      margin-top: 0;
      margin-bottom: 0;
      color: ${p => p.theme.darkBlue};
      font-weight: 500;
      font-size: 13px;

      &:hover {
        color: ${p => p.theme.darkBlue};
      }
      i[class*='icon'] > svg > g > g {
        stroke: ${p => p.theme.darkBlue};
      }

      i[class*='icon'] > svg > path {
        fill: ${p => p.theme.darkBlue};
      }
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background: ${p => p.theme.menu.activeBg};
    }

    .ant-menu-item:not(:last-child) {
      margin-bottom: 0;
    }

    .ant-menu-submenu > .ant-menu-submenu-title {
      height: auto;
    }

    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
      right: 10px;
      top: 47%;
    }

    .ant-menu-inline
      .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after {
      height: 1px;
      background: ${p => p.theme.darkBlue};
    }

    .ant-menu-submenu-open.ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow {
      top: 43%;
    }

    .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after {
      transform: rotate(45deg) translateX(-3px);
      width: 7px;
      height: 1px;
    }

    .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::before {
      transform: rotate(-45deg) translateX(3px);
      width: 7px;
      height: 1px;
    }

    .ant-menu-submenu-open.ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::before {
      transform: rotate(45deg) translateX(3px);
    }

    .ant-menu-submenu-open.ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after {
      transform: rotate(-45deg) translateX(-3px);
    }

    .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::before {
      background: ${p => p.theme.darkBlue};
    }

    .ant-menu-submenu-inline
      > .ant-menu-submenu-title:hover
      .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-inline
      > .ant-menu-submenu-title:hover
      .ant-menu-submenu-arrow::after {
      background: ${p => p.theme.darkBlue};
    }

    .ant-menu-submenu-title {
      margin-top: 6px;
      padding-left: 16px !important;
      font-size: 14px;

      > span {
        display: flex;
        align-items: center;
      }

      > span span {
        margin-left: 6px;
      }
    }

    .ant-menu-submenu-selected {
      color: ${p => p.theme.darkBlue};

      > .ant-menu-submenu-title {
        color: ${p => p.theme.darkBlue};
      }

      i[class*='icon'] > svg > path {
        fill: ${p => p.theme.darkBlue} !important;
      }
    }
  }
`

const SubMenu = styled(Menu.SubMenu)`
  color: ${p => p.theme.darkBlue};

  &.ant-menu-submenu > .ant-menu:not(:empty) {
    padding-bottom: 20px;
    background-color: ${p => p.theme.background};
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: 36px;
    line-height: 36px;
  }

  &.ant-menu-submenu-vertical > .ant-menu-submenu-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const MainMenu = styled(Menu.Item)`
  &.ant-menu-item {
    height: 40px;
    line-height: 40px;
    background-color: ${p => p.theme.background};

    a {
      font-size: 14px;
      font-weight: bold;
      color: ${p => p.theme.darkBlue};
    }

    .ant-menu-title-content {
      margin-left: 6px;
    }
  }

  &.ant-menu-item:hover,
  &.ant-menu-item-selected {
    background: #fff;
    border-radius: 0 17px 17px 0;
    &:after {
      display: none;
    }

    a {
      color: ${p => p.theme.darkBlue};
    }

    i.icon-media > svg > g {
      stroke: ${p => p.theme.darkBlue};
    }

    i[class*='icon'] > svg > path {
      fill: ${p => p.theme.darkBlue};
    }
  }
`

const MenuItem = styled(Menu.Item)`
  &.ant-menu-item {
    padding-left: 37px !important;
    text- &:active {
      background-color: ${p => p.theme.background};
    }

    > span a {
      color: ${p => p.theme.darkBlue};
      font-weight: normal;

      &:hover {
        color: ${p => p.theme.darkBlue};
      }
    }
  }
`

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 8px 0px 8px;
  margin-bottom: 0px;
  text-align: center;
  font-weight: 500;

  .logo-text {
    color: #201817;
    font-size: 13px;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 127px;
    margin-bottom: 2px;
  }
`

export default SideMenu
