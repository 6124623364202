import '@assets/index.css'
import '@assets/locales/i18n'
import './bootstrap'

import React from 'react'
import { Router } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { QueryClientProvider } from 'react-query'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import zhTW from 'antd/lib/locale/zh_TW'
import { mergeDeepRight } from 'ramda'
import { ThemeProvider } from 'styled-components'
import defaultTheme from '@assets/theme'
import useAuth, { AuthContext, AuthProvider } from '@/auth'
import { createQueryClient } from '@/services'
import { history } from '@/routes'
import createPack from '@/utils/renderer/createPack'
import i18n from 'i18next'
import useWebSystemRole from '@/hooks/useWebSystemRole'
import envStore from '@/env'
import { Loading } from '@components/Loading'

type Props = {
  theme?: { [key: string]: any }
}

const ThemeProviderFC = React.memo<
  React.PropsWithChildren<{ theme?: Record<string, string> }>
>(props => {
  const { user } = useAuth()

  const webSystemRole = useWebSystemRole()

  const _defaultTheme = React.useMemo(() => {
    if (webSystemRole === 'SystemAdmin') {
      return {
        ...defaultTheme,
        background: defaultTheme.adminBackground,
      }
    }
    return defaultTheme
  }, [webSystemRole])

  return (
    <ThemeProvider theme={mergeDeepRight(_defaultTheme, props.theme || {})}>
      {props.children}
    </ThemeProvider>
  )
})

const EnhancedApp = ({ theme = {}, children }: any) => {
  const [locale, setLocale] = React.useState(zhTW)
  const [isFetchedRuntimeEnv, setFetchedRuntimeEnv] = React.useState(
    envStore.runTimeEnv.isFetchedRunTimeEnv
  )

  const Provider = createPack(
    <ConfigProvider locale={locale} csp={{ nonce: envStore.cspNonce }} />,
    <RecoilRoot />
  )

  React.useEffect(() => {
    const handelFetchedRunTimeEnv = () => {
      setFetchedRuntimeEnv(true)
    }
    envStore.runTimeEnv.addRunTimeEnvChangeListener(handelFetchedRunTimeEnv)
    return () => {
      envStore.runTimeEnv.removeRunTimeEnvChangeListener(
        handelFetchedRunTimeEnv
      )
    }
  }, [])

  React.useEffect(() => {
    i18n.on('languageChanged', function () {
      setLocale(i18n.language === 'zhTW' ? zhTW : enUS)
    })
  }, [])

  return (
    <Provider>
      <AuthProvider>
        <ThemeProviderFC>
          <AuthContext.Consumer>
            {auth => (
              <QueryClientProvider client={createQueryClient(auth)}>
                {isFetchedRuntimeEnv ? (
                  <Router history={history}>{children}</Router>
                ) : (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100vw',
                      height: '100vh',
                      background: '#9090905b',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: 1,
                    }}>
                    <Loading />
                  </div>
                )}
              </QueryClientProvider>
            )}
          </AuthContext.Consumer>
        </ThemeProviderFC>
      </AuthProvider>
    </Provider>
  )
}

const renderer =
  (props: Props = {}) =>
  (App: React.FunctionComponent) => {
    return (
      <EnhancedApp {...props}>
        <App />
      </EnhancedApp>
    )
  }

export default renderer
