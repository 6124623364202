import envStore from '@lib/env'
import useComapnyCode from '@lib/hooks/useComapnyCode'
import { isNil } from '@lib/utils/webHelper'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import Store from 'store'

const defaultLang = navigator.language.replace('-', '_') || 'en_US'

export const useTranslationConfig = () => {
  const { i18n } = useTranslation()

  const companyCode = useComapnyCode()

  const [reloadCurrentLang, setReloadCurrentLang] = React.useState(0)

  const Langs = React.useMemo(() => ['en_US', 'zh_TW', 'zh_CN'], [])

  const lang = React.useMemo(() => {
    let lang = defaultLang
    if (isNil(companyCode)) {
      lang = Store.get('SystemAdmin_language') || defaultLang
    } else {
      lang = Store.get(`${companyCode}_language`) || defaultLang
    }
    return lang
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode, reloadCurrentLang])

  useQuery(
    'overwrite',
    async () => {
      const res = await fetch(
        `${
          process.env.REACT_APP_BASE_NAME || ''
        }/locales/${lang}/overwrite.json`
      )
      return res.json()
    },
    {
      enabled: !!lang,
      onSuccess: data => {
        Object.keys(data).forEach((ns: string) => {
          const resources = data[ns]
          i18n.addResourceBundle(lang, ns, resources, true, true)
        })
      },
    }
  )

  const initialSystemTranslation = React.useCallback(() => {
    let lang = defaultLang
    if (isNil(companyCode)) {
      lang = Store.get('SystemAdmin_language') || defaultLang
    } else {
      lang = Store.get(`${companyCode}_language`) || defaultLang
    }
    envStore.currentSystemLang = lang
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang)
    }
  }, [companyCode, i18n])

  const setSystemTranslationLang = React.useCallback(
    (lang: string) => {
      if (isNil(companyCode)) {
        Store.set('SystemAdmin_language', lang)
      } else {
        Store.set(`${companyCode}_language`, lang)
      }
      envStore.currentSystemLang = lang
      i18n.changeLanguage(lang)
      setReloadCurrentLang(c => ++c)
    },
    [companyCode, i18n]
  )

  return {
    initialSystemTranslation,
    setSystemTranslationLang,
    Langs,
    currentLang: lang,
  }
}
