import styled, { css } from 'styled-components'
import { message as AntMessage } from 'antd'
import CloseOutlined from '@ant-design/icons/CloseOutlined'
import { Toast } from 'antd-mobile'
import { compose, keys, length } from 'ramda'
import { ConfigOptions } from 'antd/lib/message'
import { IconSuccess, IconFail } from '@components/Icons'
import { theme } from '@assets/index'
import { isMobile } from '@/utils/browser-detect'
import { uuid } from '@/utils/webHelper'

interface BaseProps extends ConfigOptions {
  content?: string | React.ReactNode
  type?: 'info' | 'success' | 'error' | 'warning' | 'fail'
  closable?: boolean
  cursor?: boolean
  onClose?: () => any
  onClick?: () => any
}

AntMessage.config({ top: 64 })

export const error = (error: any) => {
  isMobile()
    ? Toast.fail(error.message || (error as any).msg || 'Server error', 2)
    : _message({
        content:
          error?.message ||
          (error as any)?.msg ||
          error?.error?.message ||
          'Server error',
        type: 'error',
        maxCount: 1,
        duration: 3,
      })
}

interface MessageProps extends BaseProps {}

export const message = ({
  content,
  type = 'success',
  duration = 3,
  cursor = false,
}: MessageProps) => {
  return isMobile()
    ? (Toast as any)[type === 'error' ? 'fail' : type](content, 2)
    : _message({
        content,
        type: type === 'fail' ? 'error' : type,
        maxCount: 1,
        duration: duration ? duration : 0,
        cursor,
      })
}

const key = uuid()

export const destroy = AntMessage.destroy

const Message = styled(({ type, cursor, ...props }) => <div {...props} />)`
  position: relative;
  width: 1000px;
  min-height: 60px;
  margin: -10px -16px;
  padding: 18px 10px 10px 40px;
  text-align: left;
  font-size: ${theme.message.fontSize};
  font-weight: ${theme.message.fontWeight};
  color: ${theme.message.color};

  ${p =>
    p.cursor &&
    css`
      cursor: pointer;
    `};

  ${p =>
    p.type === 'success' &&
    css`
      background: ${theme.message.bg};
      border: none;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    `};

  ${p =>
    p.type === 'error' &&
    css`
      background: ${theme.red100};
      border: none;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    `};

  ${p =>
    p.type === 'warning' &&
    css`
      background: #fff1f0;
      border: none;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    `};

  .anticon-info-circle > svg > path {
    fill: rgb(182, 127, 40);
  }

  .anticon-close-circle > svg > path {
    fill: #d74c4c;
  }

  .anticon-warning > svg > path {
    fill: #f34c39;
  }

  .btn {
    cursor: pointer;

    &--close {
      position: absolute;
      right: 5px;
      top: 15px;
      font-size: 18px;

      .anticon-close > svg > path {
        fill: #222;
      }
    }
  }
`

const _message = ({
  content,
  type = 'success',
  closable = false,
  onClose,
  duration = 3,
  onClick,
  cursor = false,
  ...config
}: BaseProps) => {
  if (compose(length, keys)(config) > 0) {
    AntMessage.config(config)
  }

  const handleDismiss = () => {
    hide()

    if (onClose) {
      onClose()
    }
  }

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  const hide = AntMessage.open({
    key,
    content: (
      <Message type={type} cursor={cursor}>
        <div className="flex items-center pr-24">
          {type === 'error' ? (
            <IconFail className="mr-8" />
          ) : (
            <IconSuccess className="mr-8" />
          )}
          <div className="flex-1" onClick={handleClick}>
            {content}
          </div>
          {closable && (
            <div className="btn btn--close">
              <CloseOutlined onClick={handleDismiss} />
            </div>
          )}
        </div>
      </Message>
    ) as React.ReactNode,
    duration,
    type: null as any,
  })

  return hide
}

export default message
