import { history } from '@/routes'
import { pathOr } from 'ramda'
import { matchPath } from 'react-router-dom'

type useComapnyCodeOption = {
  supportSystemAdminCode?: boolean
}

const useComapnyCode = (option?: useComapnyCodeOption) => {
  const pathResult = matchPath(history.location.pathname, {
    path: '/companies/:companyCode/(.)*',
  })
  return pathOr(
    option?.supportSystemAdminCode ? 'systemAdmin' : '',
    ['params', 'companyCode'],
    pathResult
  )
}

export default useComapnyCode
