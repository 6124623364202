import request, {
  convertData,
  Mutation,
  MutationOptions,
  useMakeMutation,
} from '@/services'
import useComapnyCode from '@/hooks/useComapnyCode'
import {
  IAct1Data,
  IAct2Data,
  IActHistory,
  IActReview,
  IPreCalculateBody,
  IActOtherEnergy,
  IEstimateQuantity,
  IEstimateQuantityQuery,
  IActReviewSetting,
  IActReviewLog,
} from './act.type'
import { useQuery, UseQueryOptions } from 'react-query'
import React from 'react'
import { prop } from 'ramda'
import {
  useAcTypeList,
  useDepartmentFullList,
  useFormTypeList,
} from '@/options'
import { toEquivalentEmissionFormat } from '@/utils/numbers'

export const useActMatchCoefficients = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/act/type1/matchCoefficients', {
        method: 'POST',
        body: { ...body, companyCode },
        displayMessage: false,
      }),
    options
  )
}

export const usePreCalculate = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: IPreCalculateBody) =>
      request('/{companyCode}/act/type1/preCalculate', {
        method: 'POST',
        body: { ...body, companyCode },
        displayMessage: false,
      }),
    options
  )
}

export const useAddActType1 = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/act/type1/activity', {
        method: 'POST',
        body: { ...body, companyCode },
      }),
    options
  )
}

export const useEditActType1 = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/act/type1/activity', {
        method: 'PUT',
        body: { ...body, companyCode },
      }),
    options
  )
}

export const useDeleteActType1 = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/act/type1/activity', {
        method: 'DELETE',
        body: { ...body, companyCode },
      }),
    options
  )
}

export const useActTypeList = (query?: any) => {
  const companyCode = useComapnyCode()
  const { byId } = useAcTypeList()
  const { showDepartmentWithOrganization } = useDepartmentFullList()
  const { byId: formTypeById } = useFormTypeList()

  const { data, isLoading, refetch } = useQuery(
    ['/{companyCode}/act/type1/activities', { companyCode, ...query }],
    { refetchOnMount: true }
  )

  const dataSource = React.useMemo(
    () =>
      convertData<IAct1Data>(
        {
          field: ['data'],
          converter: x => ({
            ...x,
            actTypeName: byId[x.actType]?.desc || x.actType,
            formTypeName: formTypeById[x.formType]?.name,
            sessionName: showDepartmentWithOrganization(x.sessionSeq),
            totalEmission: toEquivalentEmissionFormat(x.totalEmission),
          }),
        },
        data
      ),
    [data, showDepartmentWithOrganization, formTypeById]
  )

  return { dataSource, isLoading, refetch }
}

export const useActTypeById = (variables: {
  id?: number
  actType?: number
  isLeased?: boolean
}) => {
  const companyCode = useComapnyCode()

  return useQuery<IAct1Data>(
    [
      '/{companyCode}/act/type1/activity',
      { ...variables, companyCode, actSeq: variables.id },
    ],
    {
      enabled: !!variables.id,
      select: (res: any) => prop('data', res),
      refetchOnMount: true,
    }
  )
}

export const useActType2List = (query?: any) => {
  const companyCode = useComapnyCode()
  const { byId } = useAcTypeList()
  const { showDepartmentWithOrganization } = useDepartmentFullList()
  const { byId: formTypeById } = useFormTypeList()

  const { data, isLoading, refetch } = useQuery([
    '/{companyCode}/act/type2/activities',
    { companyCode, ...query },
  ])

  const dataSource = React.useMemo(
    () =>
      convertData<IAct2Data>(
        {
          field: ['data'],
          converter: x => ({
            ...x,
            actTypeName: byId[x.actType]?.desc || x.actType,
            formTypeName: formTypeById[x.formType]?.name,
            sessionName: showDepartmentWithOrganization(x.sessionSeq),
            totalEmission: toEquivalentEmissionFormat(x.totalEmission),
          }),
        },
        data
      ),
    [data, showDepartmentWithOrganization, formTypeById]
  )

  return { dataSource, isLoading, refetch }
}

export const useAddActType2 = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/act/type2/activity', {
        method: 'POST',
        body: { ...body, companyCode },
      }),
    options
  )
}

export const useEditActType2 = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/act/type2/activity', {
        method: 'PUT',
        body: { ...body, companyCode },
      }),
    options
  )
}

export const useDeleteActType2 = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/act/type2/activity', {
        method: 'DELETE',
        body: { ...body, companyCode },
      }),
    options
  )
}

export const useActType2ById = (variables: {
  id?: number
  actType: number
  isLeased?: boolean
}) => {
  const companyCode = useComapnyCode()

  return useQuery<IAct2Data>(
    [
      '/{companyCode}/act/type2/activity',
      { ...variables, companyCode, actSeq: variables.id },
    ],
    {
      enabled: !!variables.id,
      select: (res: any) => prop('data', res),
      refetchOnMount: true,
    }
  )
}

export const useAcReviewList = (query?: any) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })
  const { byId } = useAcTypeList()
  const { byId: formTypeById } = useFormTypeList()

  const { data, isLoading, refetch } = useQuery(
    ['/{companyCode}/act/review/activities', { companyCode, ...query }],
    {
      refetchOnMount: true,
    }
  )

  const dataSource = React.useMemo(
    () =>
      convertData<IActReview>(
        {
          field: ['data'],
          converter: x => ({
            ...x,
            actTypeName: byId[x?.actType]?.desc || x.actType,
            totalEmission: toEquivalentEmissionFormat(x.totalEmission),
            formTypeName: formTypeById[x.formType]?.name,
          }),
        },
        data
      ),
    [data, formTypeById]
  )

  return { dataSource, isLoading, refetch }
}

export const useEditActReview = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/act/review/activity/action', {
        method: 'POST',
        body: { ...body, companyCode },
      }),
    options
  )
}

export const useBatchEditActReview = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/act/review/activity/action/batch', {
        method: 'POST',
        body: { ...body, companyCode },
      }),
    options
  )
}

export const useActHistoryList = (query?: any) => {
  const companyCode = useComapnyCode()
  const { byId } = useAcTypeList()
  const { showDepartmentWithOrganization } = useDepartmentFullList()

  const { data, isLoading, refetch } = useQuery([
    '/{companyCode}/act/history/activitys',
    { companyCode, ...query },
  ])

  const dataSource = React.useMemo(
    () =>
      convertData<IActHistory>(
        {
          field: ['data'],
          converter: x => ({
            ...x,
            actTypeName: byId[x.actType]?.desc || x.actType,
            sessionName: showDepartmentWithOrganization(x.sessionSeq),
            totalEmission: toEquivalentEmissionFormat(x.totalEmission),
          }),
        },
        data
      ),
    [data, showDepartmentWithOrganization]
  )

  return { dataSource, isLoading, refetch }
}

export const useActOtherEnergy = (query?: any) => {
  const companyCode = useComapnyCode()
  const { byId } = useAcTypeList()
  const { showDepartmentWithOrganization } = useDepartmentFullList()

  const { data, isLoading, refetch } = useQuery(
    [
      '/{companyCode}/act/type4/activities/actOtherEnergy',
      { companyCode, ...query },
    ],
    {
      refetchOnMount: true,
    }
  )

  const dataSource = React.useMemo(
    () =>
      convertData<IActOtherEnergy>(
        {
          field: ['data'],
          converter: x => ({
            ...x,
            actTypeName: byId[x?.actType]?.desc || x?.actType,
            sessionName: showDepartmentWithOrganization(x.sessionSeq),
            totalEmission: toEquivalentEmissionFormat(x.totalEmission),
          }),
        },
        data
      ),
    [data, showDepartmentWithOrganization]
  )

  return { dataSource, isLoading, refetch }
}

export const useImportMovableCombustionByCPC = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (body: any) =>
      request(`/${companyCode}/act/type1/activity/importCpc`, {
        method: 'POST',
        body,
        headers: {},
      }),
    options
  )
}

export const usePowerInputEstimateQuantity = (
  options?: UseQueryOptions<IEstimateQuantity>
) => {
  const companyCode = useComapnyCode()

  const [queryCache, setQueryCache] = React.useState(1)
  const [query, setQuery] = React.useState<IEstimateQuantityQuery | null>(null)

  const { refetch, ...others } = useQuery<IEstimateQuantity>(
    [
      '/{companyCode}/act/type2/activity/inputPower/estimateQuantity',
      { ...query, companyCode, queryCache },
    ],
    {
      enabled: !!query,
      select: (res: any) => prop('data', res),
      refetchOnMount: false,
      ...options,
    }
  )

  const fetch = React.useCallback((query: IEstimateQuantityQuery) => {
    setQuery(query)
    setQueryCache(c => ++c)
  }, [])

  return { fetch, ...others }
}

export const useWaterUsageEstimateQuantity = (
  options?: UseQueryOptions<IEstimateQuantity>
) => {
  const companyCode = useComapnyCode()

  const [queryCache, setQueryCache] = React.useState(1)
  const [query, setQuery] = React.useState<IEstimateQuantityQuery | null>(null)

  const { refetch, ...others } = useQuery<IEstimateQuantity>(
    [
      '/{companyCode}/act/type4/activity/actWaterUsage/estimateQuantity',
      { ...query, companyCode, queryCache },
    ],
    {
      enabled: !!query,
      select: (res: any) => prop('data', res),
      refetchOnMount: false,
      ...options,
    }
  )

  const fetch = React.useCallback((query: IEstimateQuantityQuery) => {
    setQuery(query)
    setQueryCache(c => ++c)
  }, [])

  return { fetch, ...others }
}

export const useActSimpleFillin = (
  variables: { actSimpleFillin?: number },
  options?: UseQueryOptions<IAct1Data>
) => {
  const companyCode = useComapnyCode()

  return useQuery<IAct1Data>(
    ['/{companyCode}/act/simpleFillin', { ...variables, companyCode }],
    {
      enabled: !!variables.actSimpleFillin,
      select: (res: any) => prop('data', res),
      refetchOnMount: true,
      ...options,
    }
  )
}

export const useActReviewSetting = () => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useQuery<IActReviewSetting[]>(
    ['/{companyCode}/act/review/setting', { companyCode }],
    {
      select: (res: any) => prop('data', res),
      refetchOnMount: true,
    }
  )
}

export const useUpdateActReviewSetting = (
  options: MutationOptions = {}
): Mutation => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  return useMakeMutation(
    (body: any) =>
      request('/{companyCode}/act/review/setting', {
        method: 'PUT',
        body: { ...body, companyCode },
      }),
    options
  )
}
export const useActReviewLog = (variables: {
  actType?: number
  id?: number
}) => {
  const companyCode = useComapnyCode()

  return useQuery<IActReviewLog[]>(
    [
      '/{companyCode}/act/review/activity/history',
      { ...variables, actSeq: variables.id, companyCode },
    ],
    {
      enabled: !!variables.id && !!variables.actType,
      select: (res: any) => prop('data', res),
      refetchOnMount: true,
    }
  )
}
