import { Col, Row, Form } from 'antd'
import React from 'react'
import { pathOr } from 'ramda'
import { useHistory } from 'react-router-dom'
import type { IAssessItem } from '@/assessment'
import Input, { InputNumber } from '@components/ui/Input'
import Button from '@components/ui/Button'
import { rules } from '@lib/utils/form'
import { ActionTypes } from '@lib/auth'
import useRouteAuth from '@/routes/useRouteAuth'
import { useTranslation } from 'react-i18next'

type IItemFormProps = {
  sequence: number
  item: IAssessItem
  onDelete: (val: any) => void
}

const ItemForm = React.memo<IItemFormProps>(props => {
  const history = useHistory()

  const { sequence, item, onDelete } = props
  const uuid = React.useMemo(() => item?.uuid, [item?.uuid])

  const [, checkIsAuthenticated] = useRouteAuth()

  const updatable = React.useMemo(
    () =>
      checkIsAuthenticated(
        ActionTypes.UPDATABLE,
        `${history.location.pathname.replace('/items', '')}`
      ),
    []
  )

  const creatable = React.useMemo(
    () =>
      checkIsAuthenticated(
        ActionTypes.CREATABLE,
        `${history.location.pathname.replace('/items', '')}`
      ),
    []
  )

  const { t } = useTranslation()

  return (
    <>
      <Form.Item
        hidden
        name={[uuid, 'id']}
        initialValue={pathOr(null, ['id'], item)}
      />
      {item?.isNewRow && (
        <Form.Item hidden name={[uuid, 'isNewRow']} initialValue={true} />
      )}
      <div className="flex">
        <div className="flex items-center h-34 ">
          <label className="mx-10">{sequence}.</label>
        </div>
        <div>
          <Form.Item
            className="mb-15"
            name={[uuid, 'name']}
            initialValue={pathOr('', ['name'], item)}
            rules={[rules.required]}>
            <Input disabled={!creatable} />
          </Form.Item>
          <Row className="mb-15" align="middle">
            <Col span={6}>
              <Form.Item
                noStyle
                rules={[rules.required]}
                name={[uuid, 'details', 0, 'score']}
                initialValue={pathOr(3, ['score'], item?.details[0])}>
                <InputNumber disabled={!creatable} className="w-60" min={1} />
              </Form.Item>
            </Col>
            <Col className="text-center" span={2}>
              {'>'}
            </Col>
            <Col span={16}>
              <Form.Item
                noStyle
                rules={[rules.required]}
                name={[uuid, 'details', 0, 'description']}
                initialValue={pathOr('', ['description'], item?.details[0])}>
                <Input disabled={!creatable} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mb-15" align="middle">
            <Col span={6}>
              <Form.Item
                noStyle
                rules={[rules.required]}
                name={[uuid, 'details', 1, 'score']}
                initialValue={pathOr(2, ['score'], item?.details[1])}>
                <InputNumber disabled={!creatable} className="w-60" min={1} />
              </Form.Item>
            </Col>
            <Col className="text-center" span={2}>
              {'>'}
            </Col>
            <Col span={16}>
              <Form.Item
                noStyle
                rules={[rules.required]}
                name={[uuid, 'details', 1, 'description']}
                initialValue={pathOr('', ['description'], item?.details[1])}>
                <Input disabled={!creatable} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mb-15" align="middle">
            <Col span={6}>
              <Form.Item
                noStyle
                rules={[rules.required]}
                name={[uuid, 'details', 2, 'score']}
                initialValue={pathOr(1, ['score'], item?.details[2])}>
                <InputNumber disabled={!creatable} className="w-60" min={1} />
              </Form.Item>
            </Col>
            <Col className="text-center" span={2}>
              {'>'}
            </Col>
            <Col span={16}>
              <Form.Item
                noStyle
                rules={[rules.required]}
                name={[uuid, 'details', 2, 'description']}
                initialValue={pathOr('', ['description'], item?.details[2])}>
                <Input disabled={!creatable} />
              </Form.Item>
            </Col>
          </Row>
        </div>
        {updatable && (
          <Button
            style={{ minWidth: 0 }}
            className="ml-16"
            color="danger"
            onClick={() => onDelete(item)}>
            {t('common:Delete')}
          </Button>
        )}
      </div>
    </>
  )
})

export default ItemForm
