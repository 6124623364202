import { SVGProps } from 'react'
const System = (props: SVGProps<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M21.333 0A2.675 2.675 0 0 1 24 2.667v18.666A2.675 2.675 0 0 1 21.333 24H2.667A2.667 2.667 0 0 1 0 21.333V2.667A2.667 2.667 0 0 1 2.667 0zm-5.958 3A5.625 5.625 0 0 0 9.75 8.625c0 .502.072.987.196 1.45L3.823 16.2a2.813 2.813 0 0 0 3.979 3.977l6.122-6.122c.463.123.948.196 1.451.196A5.625 5.625 0 0 0 21 8.625c0-.504-.088-.984-.196-1.452-.214-.927-.655-.936-1.285-.305l-.86.86-.28.28-.286.286-.562.561-.131.132-.245.245-.511.51-.075.076a1.687 1.687 0 1 1-2.386-2.386l.034-.035.248-.248 2.668-2.668c.612-.611.656-1.024-.307-1.285A5.515 5.515 0 0 0 15.376 3z"
        fill="#44596C"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default System
