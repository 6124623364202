import { ReactText } from 'react'
import { Form } from 'antd'
import { isEmpty } from 'ramda'
import i18n from 'i18next'

export const createFormItemLayout = (
  label: number = 3,
  wrapper: number = 21
) => ({
  labelCol: {
    xs: { span: label },
    sm: { span: label },
  },
  wrapperCol: {
    xs: { span: wrapper },
    sm: { span: wrapper },
  },
})

export const thosandSeprartor = {
  formatter: (value: number | string | undefined) => {
    if (!value) {
      return value as string
    }
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  parser: (value: string | undefined) =>
    (value ? value.replace(/\$\s?|(,*)/g, '') : value) as ReactText,
}

export const currency = {
  formatter: (value: number | string | undefined) => {
    if (!value) {
      return value as string
    }
    return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  parser: (value: string | undefined) =>
    (value ? value.replace(/\$\s?|(,*)/g, '') : value) as ReactText,
}

export const percent = {
  min: 0,
  max: 100,
  formatter: (value: number | string | undefined) => {
    if (!value) {
      return value as string
    }
    return `${value}%`
  },
  parser: (value: string | undefined) =>
    (value ? value.replace('%', '') : value) as ReactText,
}

export const watt = {
  // min: 0,
  max: 1000,
  formatter: (value: number | string | undefined) => {
    if (!value) {
      return value as string
    }
    return `${value}W`
  },
  parser: (value: string | undefined) =>
    (value ? value.replace('W', '') : value) as ReactText,
}

export const formatPhoneNumber = (str: string | null) => {
  var match = str?.match(/^(\d{0,2})(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/)

  if (match) {
    return (
      `${match[1]}${isEmpty(match[2]) ? '' : `-${match[2]}`}` +
      `${isEmpty(match[3]) ? '' : `-${match[3]}`}` +
      `${isEmpty(match[4]) ? '' : `-${match[4]}`}` +
      `${isEmpty(match[5]) ? '' : `-${match[5]}`}`
    )
  }
  return null
}

export let rules = {
  required: { required: true, message: i18n.t('common:field required') },
  number: {
    type: 'number',
    message: i18n.t('common:please enter numbers'),
    transform(value: any) {
      if (!value) {
        return value
      }
      return Number(value)
    },
  },
  email: {
    required: true,
    message: i18n.t('common:please enter the correct format of email'),
    pattern: new RegExp(/^.{8,128}$/),
  },
  account: {
    required: true,
    message: i18n.t('common:the range for word length', { min: 5, max: 20 }),
    pattern: new RegExp(/^.{5,20}$/),
  },
  password: {
    required: true,
    message: i18n.t('common:the range for password rule', { min: 12, max: 20 }),
    pattern: new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{12,20}$/),
  },
  /**
   * 因密碼長度從至少 8 碼改為至少 12 碼，為避免原 8 碼密碼無法登入，增加 8 碼的 v1 版檢核
   */
  passwordV1: {
    required: true,
    message: i18n.t('common:the range for password rule', { min: 8, max: 20 }),
    pattern: new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{8,20}$/),
  },
  min: (limit: number) => ({
    validator: (_: any, value: any, callback: any) => {
      if (value < limit) {
        callback(i18n.t('common:not less than', { min: limit }))
      } else {
        callback()
      }
    },
  }),
  max: (limit: number) => ({
    validator: (_: any, value: any, callback: any) => {
      if (limit === 0) {
        return callback(i18n.t('common:The maximum number is 0'))
      }

      if (value > limit) {
        callback(i18n.t('common:not greater than', { max: limit }))
      } else {
        callback()
      }
    },
  }),
  dateRange: (min: number, max: number) => ({
    validator: (_: any, value: any, callback: any) => {
      if (!value || !Array.isArray(value) || value.length < 2) {
        callback()
      }
      const [start, end] = value
      const diffDays = end.diff(start, 'days')
      if (diffDays > max) {
        callback(i18n.t('common:not be greater than days', { max }))
      } else if (diffDays < min) {
        callback(i18n.t('common:not be less than days', { min }))
      } else {
        callback()
      }
    },
  }),
}

type UseFormProps = {
  field?: string
  initialValue?: any
}

export function useForm({ field, initialValue }: UseFormProps = {}) {
  let wrapper = field
    ? (node: React.ReactNode) => (
        <Form.Item noStyle name={field} initialValue={initialValue}>
          {node}
        </Form.Item>
      )
    : (node: React.ReactNode) => node

  return wrapper
}
