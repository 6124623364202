/**
 * 百分比: 四捨五入到小數點第四位
 * 超過小數點後5位有值(非為0)一律用科學符號標示
 */
import envStore from '@lib/env'
import {
  BigNumber,
  isNil,
  numberFractionDigitsFormat,
  toThousandSeparator,
} from '@lib/utils/webHelper'
import { not } from 'ramda'
const decimalNumber = envStore.decimalNumber

/**
 *
 * @returns string "" | 千分位分號格式 | 科學符號顯示
 */
export const expoNumber = (v: any) => {
  if (isNil(v)) {
    return ''
  }

  let temp
  const bignum = BigNumber(v)
  const isGreaterThanOne = bignum.gt(1)
  const decimalPart = String(Number(v)).split('.')[1]

  //當數值小於1 且小數點後後第5位開始有非為0 : 使用科學符號, 四捨五入到小數點第四位
  if (!isGreaterThanOne && decimalPart?.length > 5) {
    temp = bignum.toExponential(decimalNumber)
  } else {
    temp = toThousandSeparator(Number(v))
  }

  console.log('[temp]', temp)

  return temp
}

/**
 * 格式化 %
 */
export const percentNumber = (v: any) => BigNumber(v).round(decimalNumber) + ''

/**
 * 活動數據, 小數格式化 %
 * @param formatValue
 * @returns
 */
export const toLimitNumber = (formatValue: number | undefined) => {
  return not(isNil(formatValue))
    ? `${BigNumber(formatValue).mul(100).round(decimalNumber).toNumber()}%`
    : undefined
}

/**
 * 轉換公噸
 * 1公噸= 1000公斤(kg)
 * @param formatValue
 * @returns numbers
 */
export const convertTon = (formatValue: any) => {
  return typeof formatValue === 'number'
    ? BigNumber(formatValue).div(1000).toNumber()
    : formatValue
}

/**
 * 排放當量 /1000
 * convertTon
 * @param formatValue
 * @returns
 */
export const toEquivalentEmissionFormat = (formatValue: number | undefined) => {
  const ton = convertTon(formatValue)
  return expoNumber(ton)
}

/**
 * 排放係數 小數點到第10位四捨五入
 */
export const toEmissionCoeffiFormat = (
  formatValue: number | undefined | null
) => {
  if (!formatValue) {
    return null
  }
  return numberFractionDigitsFormat(10)(Number(formatValue))
}
