import {
  compose,
  flatten,
  indexBy,
  pathOr,
  pluck,
  prop,
  includes,
  isNil,
} from 'ramda'
import { useQuery, UseQueryOptions } from 'react-query'
import {
  IProcessOption,
  IEquipmentTypeOption,
  IFuelOption,
  ICurrencyOption,
  IFormTypeOption,
  IEquipmentInfoOption,
  ITransportType,
  IGoodInfo,
  ITag,
  IUnit,
  IActType,
  IEquipmentFormOption,
  IPCAFCategory,
  IGHGCategory,
  IFirmOption,
  ICustomerOption,
  IEmployeeOption,
  IPersonTranOption,
  IAirportRoute,
  IMetroFromRoute,
  IMetroToRoute,
  IScrapSelfDefinition,
  IScrapSelfTableDefinition,
} from './options.type'
import React from 'react'
import request, {
  convertData,
  Mutation,
  MutationOptions,
  useMakeMutation,
} from '@/services'
import useComapnyCode from '@/hooks/useComapnyCode'
import { useDepartmentFullList } from './organization.options.api'

export const useProcessList = () => {
  const companyCode = useComapnyCode()

  const { data, isLoading, refetch } = useQuery<IProcessOption[]>(
    ['/{companyCode}/options/processInfos', { companyCode }],
    {
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  return { data, isLoading, refetch }
}

export const useEquipmentTypeList = (variables?: { actType?: number }) => {
  const companyCode = useComapnyCode()

  const { data, isLoading, refetch } = useQuery<IEquipmentTypeOption[]>(
    ['/{companyCode}/options/equipmentType', { companyCode }],
    {
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return indexBy(prop('id'), data || [])
  }, [data])

  const datafilter = React.useMemo(() => {
    if (data && variables?.actType) {
      return data.filter(x => {
        const relatedActTypes = pathOr('', ['relatedActTypes'], x).split(',')
        return includes(String(variables?.actType), relatedActTypes)
      })
    }

    return data
  }, [data]) //eslint-disable-line

  return { data: datafilter, isLoading, refetch, byId }
}

export const useEquipmentInfoList = ({
  equipmentTypeSeq,
}: {
  equipmentTypeSeq?: number
}) => {
  const companyCode = useComapnyCode()

  const { data, isLoading, refetch } = useQuery<IEquipmentInfoOption[]>(
    ['/{companyCode}/options/equipmentInfo', { companyCode, equipmentTypeSeq }],
    {
      enabled: !!equipmentTypeSeq,
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  return { data, isLoading, refetch }
}

export const useFuelList = (
  actType?: number,
  options?: UseQueryOptions<any>
) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IFuelOption[]>(
    ['/{companyCode}/options/fuels', { companyCode, actType }],
    {
      ...options,
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return data ? indexBy(prop('id'), data) : {}
  }, [data])

  return { data, isLoading, refetch, byId }
}

export const useCurrencyList = () => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<ICurrencyOption[]>(
    ['/{companyCode}/options/currencys', { companyCode }],
    {
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  return { data, isLoading, refetch }
}

export const useFormTypeList = () => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IFormTypeOption[]>(
    ['/{companyCode}/options/formTypes', { companyCode }],
    {
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return indexBy(prop('id'), data || [])
  }, [data])

  return { data, isLoading, refetch, byId }
}

export const useCoefficientTypeList = () => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IFormTypeOption[]>(
    ['/{companyCode}/options/coefficientTypes', { companyCode }],
    {
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return indexBy(prop('id'), data || [])
  }, [data])

  return { data, isLoading, refetch, byId }
}

/**
 * 運輸設備
 */
export const useTransportTypeList = ({
  actType,
  transCategoryType,
}: {
  actType?: number
  transCategoryType?: string
}) => {
  const companyCode = useComapnyCode()

  const { data, isLoading, refetch } = useQuery<ITransportType[]>(
    [
      '/{companyCode}/options/fuels/goodTrans',
      { companyCode, actType, transCategoryType },
    ],
    {
      enabled: !!transCategoryType && !!actType,
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  return { data, isLoading, refetch }
}

export const useAddTags = (options: MutationOptions = {}): Mutation => {
  const companyCode = useComapnyCode()

  return useMakeMutation(
    (tags: string[]) =>
      request('/{companyCode}/options/tags', {
        method: 'POST',
        body: { tags, companyCode },
        displayMessage: false,
      }),
    options
  )
}

export const useTags = () => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<ITag[]>(
    ['/{companyCode}/options/tags', { companyCode }],
    {
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return indexBy(prop('id'), data || [])
  }, [data])

  return { data, isLoading, refetch, byId }
}

export const useGoodsInfoList = (query?: any) => {
  const companyCode = useComapnyCode()

  const { byId } = useUnits()

  const { data, isLoading, refetch } = useQuery<IGoodInfo[]>(
    ['/{companyCode}/options/goodsInfo', { companyCode, ...query }],
    {
      refetchOnMount: true,
      select: data => {
        return pathOr([], ['data'], data).map((goodInfo: IGoodInfo) => ({
          ...goodInfo,
          unitString: byId[goodInfo.unitSeq]?.name || '',
        }))
      },
    }
  )

  return { data, isLoading, refetch }
}

export const useUnits = () => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IUnit[]>(
    ['/{companyCode}/options/units', { companyCode }],
    {
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return indexBy(prop('id'), data || [])
  }, [data])

  return { data, isLoading, refetch, byId }
}

export const useAcTypeList = () => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IActType[]>(
    ['/{companyCode}/options/actTypes', { companyCode }],
    {
      select: data => {
        return compose<any, IActType[]>(pathOr([], ['data']))(data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return data ? indexBy(prop('value'), data) : {}
  }, [data])

  return { data, isLoading, byId, refetch }
}

export const useAcTypeListByMbrRoleSeq = (memberRoleSeq?: any) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IActType[]>(
    [
      '/{companyCode}/options/actTypes/{memberRoleSeq}',
      { companyCode, memberRoleSeq },
    ],
    {
      enabled: !!memberRoleSeq,
      select: data => {
        return compose<any, IActType[]>(pathOr([], ['data']))(data)
      },
    }
  )

  return { data, isLoading, refetch }
}

export const useEquipmentFormList = () => {
  const companyCode = useComapnyCode()

  const { data, isLoading, refetch } = useQuery<IEquipmentFormOption[]>(
    ['/{companyCode}/options/equipmentForm', { companyCode }],
    {
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return indexBy(prop('id'), data || [])
  }, [data])

  return { data, isLoading, refetch, byId }
}

export const usePCAFCategories = () => {
  const companyCode = useComapnyCode()

  const { data, isLoading, refetch } = useQuery<IPCAFCategory[]>(
    ['/{companyCode}/options/pcafCategorys', { companyCode }],
    {
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return indexBy(prop('id'), data || [])
  }, [data])

  const levekById = React.useMemo(() => {
    return indexBy(prop('id'), flatten(pluck('pcafDataLevels', data || [])))
  }, [data])

  return { byId, data, isLoading, levekById, refetch }
}

export const useGHGCategories = () => {
  const companyCode = useComapnyCode()

  const { data, isLoading, refetch } = useQuery<IGHGCategory[]>(
    ['/{companyCode}/options/ghgCategorys', { companyCode }],
    {
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return indexBy(prop('id'), data || [])
  }, [data])

  return { byId, data, isLoading, refetch }
}

export const useFirmsOption = (
  query?: any,
  options?: UseQueryOptions<IFirmOption[]>
) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IFirmOption[]>(
    ['/{companyCode}/options/firms', { companyCode, ...query }],
    options
  )

  const dataSource = React.useMemo(
    () => convertData<IFirmOption>({ field: ['data'] }, data),
    [data]
  )

  return { dataSource, isLoading, refetch }
}

export const useCustomersOption = (
  query?: any,
  options?: UseQueryOptions<ICustomerOption[]>
) => {
  const companyCode = useComapnyCode()

  const { data, isLoading, refetch } = useQuery<ICustomerOption[]>(
    ['/{companyCode}/options/customers', { companyCode, ...query }],
    options
  )

  const dataSource = React.useMemo(
    () => convertData<ICustomerOption>({ field: ['data'] }, data),
    [data]
  )

  return { dataSource, isLoading, refetch }
}

export const useEmployeesOption = (
  query?: any,
  options?: UseQueryOptions<IEmployeeOption[]>
) => {
  const companyCode = useComapnyCode()

  const { enabled = true } = options || {}

  const { showDepartmentWithOrganization } = useDepartmentFullList()

  const { data, isLoading, refetch } = useQuery<IEmployeeOption[]>(
    ['/{companyCode}/options/employees', { companyCode, ...query }],
    { ...options, enabled: !!companyCode && enabled }
  )

  const dataSource = React.useMemo(
    () =>
      convertData<IEmployeeOption>(
        {
          field: ['data'],
          converter: x => ({
            ...x,
            sessionName: showDepartmentWithOrganization(x.sessionSeq),
          }),
        },
        data
      ),
    [data, showDepartmentWithOrganization]
  )

  return { dataSource, isLoading, refetch }
}

export const usePersonTransList = (
  actType?: number,
  transCategoryType?: number,
  options?: UseQueryOptions<any>
) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IPersonTranOption[]>(
    [
      '/{companyCode}/options/fuels/personTrans',
      { companyCode, actType, transCategoryType },
    ],
    {
      ...options,
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return data ? indexBy(prop('id'), data) : {}
  }, [data])

  return { data, isLoading, refetch, byId }
}

export const useAirportRouteList = (
  variables?: Record<string, any>,
  options?: UseQueryOptions<any>
) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IAirportRoute[]>(
    ['/{companyCode}/airportRouteInfo/list', { companyCode, ...variables }],
    {
      ...options,
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return data ? indexBy(prop('id'), data) : {}
  }, [data])

  return { data, isLoading, refetch, byId }
}

export const useMetroFromRouteList = (
  variables?: Record<string, any>,
  options?: UseQueryOptions<any>
) => {
  const { data, isLoading, refetch } = useQuery<IMetroFromRoute[]>(
    ['/metroInfo/from/list', { ...variables }],
    {
      ...options,
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return data ? indexBy(prop('id'), data) : {}
  }, [data])

  return { data, isLoading, refetch, byId }
}

export const useMetroToRouteList = (
  variables?: Record<string, any>,
  options?: UseQueryOptions<any>
) => {
  const { data, isLoading, refetch } = useQuery<IMetroToRoute[]>(
    ['/metroRouteInfo/list', { ...variables }],
    {
      ...options,
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const byId = React.useMemo(() => {
    return data ? indexBy(prop('id'), data) : {}
  }, [data])

  return { data, isLoading, refetch, byId }
}

export const useScrapSelfDefinitions = (options?: UseQueryOptions<any>) => {
  const companyCode = useComapnyCode({ supportSystemAdminCode: true })

  const { data, isLoading, refetch } = useQuery<IScrapSelfDefinition[]>(
    ['/{companyCode}/options/scrapSelfDefinitions', { companyCode }],
    {
      refetchOnMount: true,
      ...options,
      select: data => {
        return pathOr([], ['data'], data)
      },
    }
  )

  const scraps = React.useMemo<IScrapSelfTableDefinition[]>(() => {
    let mainCategoryRowSpanCount: Record<string, number> = {}
    data?.forEach(scrap => {
      if (isNil(mainCategoryRowSpanCount[scrap.mainCategory])) {
        mainCategoryRowSpanCount[scrap.mainCategory] = 1
      } else {
        mainCategoryRowSpanCount[scrap.mainCategory] += 1
      }
    })
    return (data || []).map(scrap => {
      const mainCategoryRowSpan =
        mainCategoryRowSpanCount[scrap.mainCategory] || 0
      if (mainCategoryRowSpan > 0) {
        delete mainCategoryRowSpanCount[scrap.mainCategory]
      }
      return {
        ...scrap,
        mainCategoryRowSpan,
      }
    })
  }, [data])

  return { isLoading, refetch, scraps }
}
